import React from 'react'
import ReactDOM from 'react-dom'
import ScriptCache from 'common/maps/ScriptCache'
import MarkerClusterer from 'common/maps/markerclusterer'
import {FormattedMessage, injectIntl} from 'react-intl'
import {mapStyles} from 'components/common/MapStyle'
import PropTypes from 'prop-types'

const {getLocations, getAllLocations} = require('backend/locations')

/**
 * Resources
 * https://developers.google.com/maps/articles/toomanymarkers
 * https://developers.google.com/my-business/content/location-data#batch_get
 * source http://group.movenpick.com/standorte/
 */
class ModBlockLocations extends React.Component {
  constructor(props) {
    super(props)

    this.findtx = null
    this.divMap = null
    this.scriptCache = null
    this.markerClusterer = null
    this.map = null
    this.infowindow = null

    this.state = {
      locations: null,
      selectedBrand: 'ALL',
    }

    this.refreshMarkers = this.refreshMarkers.bind(this)
    this.refreshPosition = this.refreshPosition.bind(this)
    this.selectResult = this.selectResult.bind(this)
    this.selectMyPosition = this.selectMyPosition.bind(this)
    this.onSelectFilterSelect = this.onSelectFilterSelect.bind(this)
    this.onSelectFilterButton = this.onSelectFilterButton.bind(this)
    this.newMarker = this.newMarker.bind(this)

    const {formatMessage} = this.props.intl

    const fakeMessagesForTheMessagesParser = [
      <FormattedMessage id="blockLocations.allLocations" defaultMessage="All Locations" />,
      <FormattedMessage id="blockLocations.marcheMovenpick" defaultMessage="Marché Mövenpick" />,
      <FormattedMessage
        id="blockLocations.marcheRestaurants"
        defaultMessage="Marché Restaurants"
      />,
      <FormattedMessage
        id="blockLocations.movenpickRestaurants"
        defaultMessage="Mövenpick Restaurants"
      />,
      <FormattedMessage id="blockLocations.beefGrillBar" defaultMessage="BEEF! Grill & Bar" />,
      <FormattedMessage id="blockLocations.cindysDiner" defaultMessage="Cindy’s Diner" />,
      <FormattedMessage id="blockLocations.palavrion" defaultMessage="Palavrion" />,
      <FormattedMessage id="blockLocations.movenpickCafe" defaultMessage="Mövenpick Café" />,
      <FormattedMessage id="blockLocations.laekkert" defaultMessage="Laekkert" />,
      <FormattedMessage id="blockLocations.whiteMonkey" defaultMessage="White Monkey" />,
      <FormattedMessage id="blockLocations.zigolini" defaultMessage="Zigolini" />,
      <FormattedMessage id="blockLocations.openWebsite" defaultMessage="Open Website" />,
      <FormattedMessage id="blockLocations.phone" defaultMessage="Phone" />,
    ]

    this.brands = {
      ALL: {
        translation: formatMessage({
          id: 'blockLocations.allLocations',
          defaultMessage: 'All Locations',
        }),
        pin: require('static/img/icon_alle_standorte_01.png'),
        pinSvg: require('static/img/icon_alle_standorte_02.svg'),
        location: {
          lat: 16.245002,
          long: 16.105957,
          zoom: 2,
        },
      },
      MM: {
        translation: formatMessage({
          id: 'blockLocations.marcheMovenpick',
          defaultMessage: 'Marché Mövenpick',
        }),
        pin: require('static/img/icon_marche_moev_01.png'),
        pinSvg: require('static/img/icon_marche_moev_02.svg'),
        location: {
          lat: 16.245002,
          long: 16.245002,
          zoom: 2,
        },
      },
      MA: {
        translation: formatMessage({
          id: 'blockLocations.marcheRestaurants',
          defaultMessage: 'Marché Restaurants',
        }),
        pin: require('static/img/icon_marche_rest_01.png'),
        pinSvg: require('static/img/icon_marche_rest_02.svg'),
        location: {
          lat: 16.245002,
          long: 16.245002,
          zoom: 2,
        },
      },
      MR: {
        translation: formatMessage({
          id: 'blockLocations.movenpickRestaurants',
          defaultMessage: 'Mövenpick Restaurants',
        }),
        pin: require('static/img/icon_moev_rest_01.png'),
        pinSvg: require('static/img/icon_moev_rest_02.svg'),
        location: {
          lat: 51.3411362,
          long: 9.3502756,
          zoom: 5,
        },
      },
      B: {
        translation: formatMessage({
          id: 'blockLocations.beefGrillBar',
          defaultMessage: 'BEEF! Grill & Bar',
        }),
        pin: require('static/img/icon_beef_01.png'),
        pinSvg: require('static/img/icon_beef_02.svg'),
        location: {
          lat: 51.3411362,
          long: 9.3502756,
          zoom: 6,
        },
      },
      C: {
        translation: formatMessage({
          id: 'blockLocations.cindysDiner',
          defaultMessage: 'Cindy’s Diner',
        }),
        pin: require('static/img/icon_cindys_01.png'),
        pinSvg: require('static/img/icon_cindys_02.svg'),
        location: {
          lat: 51.3411362,
          long: 9.3502756,
          zoom: 6,
        },
      },
      P: {
        translation: formatMessage({
          id: 'blockLocations.palavrion',
          defaultMessage: 'Palavrion',
        }),
        pin: require('static/img/icon_palavrion_01.png'),
        pinSvg: require('static/img/icon_palavrion_02.svg'),
        location: {
          lat: 51.3411362,
          long: 9.3502756,
          zoom: 6,
        },
      },
      K: {
        translation: formatMessage({
          id: 'blockLocations.movenpickCafe',
          defaultMessage: 'Mövenpick Café',
        }),
        pin: require('static/img/icon_moev_cafe_01.png'),
        pinSvg: require('static/img/icon_moev_cafe_02.svg'),
        location: {
          lat: 16.245002,
          long: 16.245002,
          zoom: 2,
        },
      },
      L: {
        translation: formatMessage({
          id: 'blockLocations.laekkert',
          defaultMessage: 'Laekkert',
        }),
        pin: require('static/img/icon_laekkert_01.png'),
        pinSvg: require('static/img/icon_laekkert_02.svg'),
        location: {
          lat: 51.3411362,
          long: 9.3502756,
          zoom: 6,
        },
      },
      W: {
        translation: formatMessage({
          id: 'blockLocations.whiteMonkey',
          defaultMessage: 'White Monkey',
        }),
        pin: require('static/img/icon_whitemonkey_01.png'),
        pinSvg: require('static/img/icon_whitemonkey_02.svg'),
        location: {
          lat: 51.3411362,
          long: 9.3502756,
          zoom: 6,
        },
      },
      Z: {
        translation: formatMessage({
          id: 'blockLocations.zigolini',
          defaultMessage: 'Zigolini',
        }),
        pin: require('static/img/icon_zigolini_01.png'),
        pinSvg: require('static/img/icon_zigolini_02.svg'),
        location: {
          lat: 51.3411362,
          long: 9.3502756,
          zoom: 6,
        },
      },
    }
  }

  selectResult(event) {
    if (this.infowindow) {
      this.infowindow.close()
    }

    const input = ReactDOM.findDOMNode(this.findtx)
    let geocoder = new google.maps.Geocoder()
    geocoder.geocode(
      {
        address: input.value,
      },
      (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          this.map.setCenter(results[0].geometry.location)
          this.map.fitBounds(results[0].geometry.viewport)
        }
      }
    )
  }

  selectMyPosition() {
    if (typeof navigator !== 'undefined') {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          this.map.setZoom(11)
          this.map.setCenter(new google.maps.LatLng(pos.coords.latitude, pos.coords.longitude))
        },
        (err) => {
          // TODO Message to user
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 1000,
        }
      )
    }
  }

  onSelectFilterButton(event) {
    this.onSelectFilter(event.target.dataset.filter)
  }

  onSelectFilterSelect(event) {
    this.onSelectFilter(event.target.value)
  }

  onSelectFilter(brandId) {
    this.setState({
      selectedBrand: brandId,
    })
  }

  newMarker(location) {
    const {formatMessage} = this.props.intl

    if (!location) {
      return null
    }
    if (!this.brands.hasOwnProperty(location.categoryId)) {
      return null
    }

    const latLng = new google.maps.LatLng(location.latitude, location.longitude)
    const brand = this.brands[location.categoryId]
    let address = location.addressLines ? location.addressLines.join('<br />') + '<br />' : ''
    address += location.postalCode + ' ' + location.locality + '<br />'
    const openingHours = ''
    const i18nOpenWebsite = formatMessage({
      id: 'blockLocations.openWebsite',
      defaultMessage: 'Open Website',
    })
    const i18nPhone = formatMessage({
      id: 'blockLocations.phone',
      defaultMessage: 'Phone',
    })
    const content = `
    <b>${location.locationName}</b><br>
    <span>${address}</span><br>
    <span>${i18nPhone}. ${location.primaryPhone}</span><br>
    <span>${openingHours}</span><br><br>
    <a href="${location.websiteUrl}" target="_blank" rel="noopener">${i18nOpenWebsite}</a>`

    const infowindow = new google.maps.InfoWindow({
      content: content,
      size: new google.maps.Size(70, 50),
    })

    let marker = new google.maps.Marker({
      position: latLng,
      draggable: false,
      icon: new google.maps.MarkerImage(brand.pin, new google.maps.Size(30, 45)),
      title: location.locationName,
    })

    google.maps.event.addListener(marker, 'click', () => {
      if (this.infowindow) {
        this.infowindow.close()
      }
      infowindow.open(this.map, marker)
      this.infowindow = infowindow
    })

    return marker
  }

  refreshMarkers() {
    if (
      !(typeof google !== 'undefined' && typeof google.maps !== 'undefined' && this.state.locations)
    ) {
      // Map is not loaded yet
      return
    }

    if (!this.map.getBounds()) {
      return
    }

    const {locations, selectedBrand} = this.state
    let markers = []

    for (let key in locations) {
      if (locations.hasOwnProperty(key)) {
        const location = locations[key]
        if (selectedBrand === 'ALL' || selectedBrand === location.categoryId) {
          const marker = this.newMarker(location)
          if (marker) {
            markers.push(marker)
          }
        }
      }
    }

    let options = {
      maxZoom: 13,
      gridSize: 50,
      styles: [
        {
          url: this.brands[selectedBrand].pin,
          height: 45,
          width: 30,
          anchor: [7, 0],
          textColor: '#ffffff',
          textSize: 13,
        },
      ],
    }

    if (this.markerClusterer) {
      this.markerClusterer.clearMarkers()
      this.markerClusterer.setStyles(options.styles)
      this.markerClusterer.addMarkers(markers, false)
    } else {
      this.markerClusterer = new MarkerClusterer(this.map, markers, options)
    }
  }

  refreshPosition() {
    if (!this.map) {
      return
    }

    const {selectedBrand} = this.state
    const {location} = this.brands[selectedBrand]
    this.map.setZoom(location.zoom)
    this.map.setCenter(new google.maps.LatLng(location.lat, location.long))
  }

  loadMap() {
    if (!(typeof google !== 'undefined' && typeof google.maps !== 'undefined')) {
      // Map is not loaded yet
      return
    }

    //************************************************************************************
    // Init Map
    const {selectedBrand} = this.state
    const location = this.brands[selectedBrand].location

    const node = ReactDOM.findDOMNode(this.divMap)
    const {lat, long, zoom} = location
    const center = new google.maps.LatLng(lat, long)
    const mapConfig = Object.assign(
      {},
      {
        center: center,
        zoom: zoom,
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
        },
        panControl: false,
        zoomControl: true,
        zoomControlOptions: {
          style: google.maps.ZoomControlStyle.SMALL,
        },
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      }
    )

    if (!node) {
      return
    }

    let map = new google.maps.Map(node, mapConfig)
    map.setOptions({styles: mapStyles})
    this.map = map

    //************************************************************************************
    // Add Autocomplete
    const input = ReactDOM.findDOMNode(this.findtx)
    let autocomplete = new google.maps.places.Autocomplete(input, {
      types: ['geocode'],
    })
    autocomplete.bindTo('bounds', map)

    //************************************************************************************
    // On Place change
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      let place = autocomplete.getPlace()
      if (place.geometry.viewport) {
        map.fitBounds(place.geometry.viewport)
      } else {
        map.setCenter(place.geometry.location)
        map.setZoom(14)
      }
    })

    this.refreshMarkers()
  }

  componentDidMount() {
    const {currentLanguage, voConfig} = this.props

    const scriptName = 'googleMaps_' + currentLanguage
    let script = {}
    script[scriptName] =
      'https://maps.googleapis.com/maps/api/js?key=' +
      voConfig.publicConfig.googleMapsApiKey +
      '&language=' +
      currentLanguage +
      '&libraries=places,language'

    if (typeof window !== 'undefined') {
      this.scriptCache = ScriptCache(window, script)

      getAllLocations(voConfig.publicConfig)
        .then((result) => {
          this.setState({locations: result})
        })
        .catch(console.error)

      this.scriptCache[scriptName].onLoad((err, tag) => {
        this.loadMap()
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.refreshMarkers()
    this.refreshPosition()
  }

  render() {
    const {locations, selectedBrand} = this.state
    const {currentLanguage} = this.props

    let locCatId = []

    for (let key in locations) {
      if (locations.hasOwnProperty(key)) {
        const location = locations[key]

        if (!locCatId.includes(location.categoryId)) {
          locCatId.push(location.categoryId)
        }
      }
    }

    const buttons = Object.entries(this.brands).map((item, index) => {
      const [brandId, brandObject] = item
      const selected = selectedBrand === brandId ? 'selected' : ''

      if (locCatId.includes(brandId) || brandId == 'ALL') {
        return (
          <button
            aria-label={'select ' + brandObject.translation}
            key={index}
            className={selected}
            type="button"
            data-filter={brandId}
            onClick={this.onSelectFilterButton}
          >
            <img src={brandObject.pinSvg} />
            <p>{brandObject.translation}</p>
          </button>
        )
      }
    })

    let selectBox = Object.entries(this.brands).map((item, index) => {
      const [brandId, brandObject] = item

      if (locCatId.includes(brandId) || brandId == 'ALL') {
        return (
          <option key={index} value={brandId}>
            {brandObject.translation}
          </option>
        )
      }
    })

    return (
      <div className="block-locations bg-grey">
        <div className="finder-container">
          <div className="finder">
            <p>
              <FormattedMessage
                id="blockLocations.searchLocation"
                defaultMessage="Search location"
              />
            </p>
            <div>
              <input type="text" ref={(ref) => (this.findtx = ref)} />
              <button
                aria-label="search location"
                type="button"
                className="btn-search"
                onClick={this.selectResult}
              >
                <div />
              </button>
            </div>
          </div>
          <div>
            <p>Filter</p>
            <select name="selector" onChange={this.onSelectFilterSelect} value={selectedBrand}>
              {selectBox}
            </select>
          </div>
        </div>
        <div className="mapwrapper">
          <div className="map" ref={(ref) => (this.divMap = ref)} />
        </div>
        <div className="maplegend">{buttons}</div>
      </div>
    )
  }
}

ModBlockLocations.propTypes = {
  content: PropTypes.object,
  currentLanguage: PropTypes.string.isRequired,
  voConfig: PropTypes.object.isRequired,
}

export default injectIntl(ModBlockLocations)
