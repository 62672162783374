export function setDisableGoogleAnalytics() {
  disableGoogleAnalytics()
  localStorage.setItem('googleAnalyticsDisabled', JSON.stringify(true))
  return false
}

export async function disableGoogleAnalytics() {
  // const disableGoogleAnalyticsKey = 'ga-disable-' + voConfig.publicConfig.googleMapsApiKey
  // window[disableGoogleAnalyticsKey] = true
  window['ga-disable-UA-83701570-1'] = true
}
