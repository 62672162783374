import React from 'react'
import NavLink from './common/NavLink'
import ModSvg from 'components/common/ModSvg'
import {hasContent} from 'common/DDUtil'
import {Arrow} from './common/ModSvg'
import PropTypes from 'prop-types'

export default class ModBreadCrumb extends React.Component {
  constructor(props) {
    super(props)

    this.findActiveNodes = this.findActiveNodes.bind(this)
  }

  findActiveNodes(navigationTree, slugs) {
    let activeNodes = []
    if (navigationTree && slugs.length > 0) {
      let slug = slugs.shift()

      for (let i = 0; i < navigationTree.length; i++) {
        const item = navigationTree[i]
        if (item._i18nCL && item._i18nCL.slug === slug) {
          activeNodes.push(item)
          return activeNodes.concat(this.findActiveNodes(item.children, slugs))
        }
      }
    }
    return []
  }

  render() {
    const {navigationTree, pathName, currentLanguage} = this.props

    if (!navigationTree || !hasContent(pathName)) {
      return null
    }

    const slugs = pathName.split('/')
    slugs.shift() // remove empty slug
    slugs.shift() // remove lang
    let activeNodes = this.findActiveNodes(navigationTree.children, slugs)

    if (activeNodes.length > 0) {
      if (!activeNodes[0]._i18nCL.showInMenu) {
        activeNodes = []
      }
    }

    if (!(activeNodes && activeNodes.length > 0)) {
      return null
    }

    let breadcrumb = activeNodes.map(function (activeItem, index) {
      if (!activeItem) {
        return null
      }

      let slug = <span key={index}>{activeItem._i18nCL.label}</span>
      let icon = null
      if (index > 0) {
        icon = <ModSvg icon={Arrow} />
        slug = (
          <NavLink to={activeItem._i18nCL.relativeUrl} onlyActiveOnIndex>
            {activeItem._i18nCL.label}
          </NavLink>
        )
      }

      return (
        <li key={index} className="icon">
          {icon}
          {slug}
        </li>
      )
    })

    return (
      <div className="breadcrumb">
        <ul>{breadcrumb}</ul>
      </div>
    )
  }
}

ModBreadCrumb.propTypes = {
  pathName: PropTypes.string.isRequired,
  currentLanguage: PropTypes.string.isRequired,
}
