import React from 'react'
import ModRichText from 'components/common/ModRichText'
import ModImgTag from 'components/common/ModImgTag'
import ModSvg from 'components/common/ModSvg'
import {CloseFramed} from '../common/ModSvg'
import PropTypes from 'prop-types'

export default class ModBlockGrid extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeCell: null,
    }

    this.toggleContent = this.toggleContent.bind(this)
    this.closeContent = this.closeContent.bind(this)
  }

  toggleContent(e) {
    const cellImg = e.currentTarget
    if (cellImg) {
      let id = cellImg.dataset.id
      if (id === this.state.activeCell) {
        id = null
      }
      this.setState({
        activeCell: Number(id),
      })
    }
  }

  closeContent(e) {
    this.setState({
      activeCell: null,
    })
  }

  render() {
    const {content, pathname} = this.props

    const list = content.list.map((item, index) => {
      if (!item._i18nCL) {
        return null
      }
      const i18n = item._i18nCL
      return (
        <div
          key={index}
          className={
            'block-grid-cell bg-white' + (this.state.activeCell === index ? ' is-active-cell' : '')
          }
        >
          <div className="content">
            <div className="block-grid-cell-image" onClick={this.toggleContent} data-id={index}>
              <ModImgTag imgObject={item.image} width={405} height={202} />
            </div>
            <div className="block-grid-cell-content">
              <ModRichText richText={i18n.text} pathname={pathname} />
              <button
                aria-label="close cell content"
                className="block-grid-cell-content-close icon"
                onClick={this.closeContent}
              >
                <ModSvg icon={CloseFramed} />
              </button>
            </div>
          </div>
        </div>
      )
    })
    return <div className="block-grid">{list}</div>
  }
}

ModBlockGrid.propTypes = {
  content: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
}
