import React from 'react'
import NavLink from 'components/common/NavLink'
import ModSvg from 'components/common/ModSvg'
import {getCurrentLanguageOrFallBackByPath} from 'common/Languages'
import {LinkDoc, LinkExternalFilled, LinkFilled, LinkImage} from './ModSvg'
import {setDisableGoogleAnalytics} from './HelperFunction'
import PropTypes from 'prop-types'

const IMAGE_TYPES = ['jpg', 'jpeg', 'png', 'gif', 'svg']

export default class ModAnchorTag extends React.Component {
  static getLinkIconType(linkObject, hasIcon) {
    if (!hasIcon) return null

    let iconType = null
    if (linkObject.hasOwnProperty('url')) {
      if (linkObject.url === '') {
        iconType = <ModSvg icon={LinkDoc} />
      }
      if (linkObject.url.startsWith('mailto:')) {
        iconType = <ModSvg icon={LinkFilled} />
      } else {
        iconType = <ModSvg icon={LinkExternalFilled} />
      }
    } else if (linkObject.hasOwnProperty('navigationNode')) {
      iconType = <ModSvg icon={LinkFilled} />
    } else if (linkObject.hasOwnProperty('overlay')) {
      iconType = <ModSvg icon={LinkFilled} />
    } else if (linkObject.hasOwnProperty('document')) {
      if (IMAGE_TYPES.indexOf(linkObject.document.type) > -1) {
        iconType = <ModSvg icon={LinkImage} />
      } else {
        iconType = <ModSvg icon={LinkDoc} />
      }
    }
    return iconType
  }

  static invalidateChildren(children) {
    if (typeof children === 'string' || children instanceof Array) {
      return <span>{children}</span>
    } else if (typeof children === 'undefined') {
      return null
    }
    return children
  }

  render() {
    let children = ModAnchorTag.invalidateChildren(this.props.children)

    if (!(typeof this.props.linkObject === 'object')) {
      return children
    }

    const {linkObject, pathname, hasIcon} = this.props

    try {
      const linkIcon = ModAnchorTag.getLinkIconType(linkObject, hasIcon)
      if (linkObject.hasOwnProperty('url')) {
        if (linkObject.url === '') {
          return children
        }
        if (linkObject.url.startsWith('mailto:')) {
          return (
            <a className={'a-mail'} href={linkObject.url}>
              {linkIcon}
              {children}
            </a>
          )
        }
        if (linkObject.url.startsWith('disableGoogleAnalytics')) {
          return (
            <a id="ga-opt-out" href="#!" onClick={setDisableGoogleAnalytics}>
              {children}
            </a>
          )
        }
        const pat = /^\/.*/i
        if (pat.test(linkObject.url)) {
          return (
            <a className={'a-external'} href={linkObject.url} target="_self" rel="noopener">
              {children}
            </a>
          )
        }

        return (
          <a className={'a-external'} href={linkObject.url} target="_blank" rel="noopener">
            {linkIcon}
            {children}
          </a>
        )
      }

      // Url Multi-Language is currently only used in BLock Teaser Spot
      else if (linkObject.hasOwnProperty('urlMultiLanguage')) {
        if (linkObject.urlMultiLanguage._i18nCL.url === '') {
          return children
        }
        if (linkObject.urlMultiLanguage._i18nCL.url.startsWith('mailto:')) {
          return (
            <a className={'a-mail'} href={linkObject.urlMultiLanguage._i18nCL.url}>
              {linkIcon}
              {children}
            </a>
          )
        }
        if (linkObject.urlMultiLanguage._i18nCL.url.startsWith('disableGoogleAnalytics')) {
          return (
            <a id="ga-opt-out" href="#!" onClick={setDisableGoogleAnalytics}>
              {children}
            </a>
          )
        }
        return (
          <a
            className={'a-external'}
            href={linkObject.urlMultiLanguage._i18nCL.url}
            target="_blank"
          >
            {linkIcon}
            {children}
          </a>
        )
      } else if (linkObject.hasOwnProperty('overlay') && pathname) {
        return (
          <NavLink
            className={'a-overlay'}
            to={{
              pathname: pathname,
              query: {overlay: linkObject.overlay},
            }}
          >
            {linkIcon}
            {children}
          </NavLink>
        )
      } else if (linkObject.hasOwnProperty('navigationNode')) {
        const currentLanguage = getCurrentLanguageOrFallBackByPath(pathname)
        const navigationNode = linkObject.navigationNode

        if (navigationNode.hasOwnProperty('node') && navigationNode.node && currentLanguage) {
          // *************************************************************************************************************
          // internal link with anchor support
          const i18n = navigationNode.node.i18n.hasOwnProperty(currentLanguage)
            ? navigationNode.node.i18n[currentLanguage]
            : null
          if (i18n) {
            if (navigationNode.anchor && navigationNode.anchor.blockTitle) {
              const blockTitle = navigationNode.anchor.blockTitle
              if (blockTitle.i18n && blockTitle.i18n.hasOwnProperty(currentLanguage)) {
                const anchor = blockTitle.i18n[currentLanguage].anchor
                return (
                  <NavLink
                    className={'a-internal'}
                    to={{
                      pathname: i18n.relativeUrl,
                      hash: '#' + anchor.toLowerCase(),
                    }}
                  >
                    {linkIcon}
                    {children}
                  </NavLink>
                )
              }
            }
            return (
              <NavLink className={'a-internal'} to={i18n.relativeUrl}>
                {linkIcon}
                {children}
              </NavLink>
            )
          }
        } else if (navigationNode.hasOwnProperty('i18n') && navigationNode.i18n) {
          // *************************************************************************************************************
          // legacy anchor support
          const i18n = navigationNode.i18n.hasOwnProperty(currentLanguage)
            ? navigationNode.i18n[currentLanguage]
            : null
          return (
            <NavLink className={'a-internal'} to={i18n.relativeUrl}>
              {linkIcon}
              {children}
            </NavLink>
          )
        }
      } else if (
        linkObject.hasOwnProperty('document') &&
        typeof this.props.linkObject.document === 'object'
      ) {
        let mediaUrl = linkObject.document.cloudinaryMedia.url
        if (linkObject.document._i18nCL && linkObject.document._i18nCL.cloudinaryMedia) {
          // use i18 media if present
          mediaUrl = linkObject.document._i18nCL.cloudinaryMedia.url
        }
        return (
          <a className={'a-document'} href={mediaUrl} target="_blank" rel="noopener">
            {linkIcon}
            {children}
          </a>
        )
      }
    } catch (e) {
      console.warn(e)
    }

    return children
  }
}

ModAnchorTag.propTypes = {
  linkObject: PropTypes.shape({
    url: PropTypes.string,
    navigationNode: PropTypes.shape({
      relativeUrl: PropTypes.string,
    }),
    document: PropTypes.shape({
      cloudinaryMedia: PropTypes.shape({
        url: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      }),
    }),
  }),
  pathname: PropTypes.string.isRequired,
  hasIcon: PropTypes.bool,
}
