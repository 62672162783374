import * as React from 'react'
import {FormattedMessage} from 'react-intl'
import PropTypes from 'prop-types'

const lazyLoading = false

export default class ModBlockVimeo extends React.Component {
  constructor(props) {
    super(props)

    this.lazyComponent = null
    this.state = {visible: false}

    this.startObserve = this.startObserve.bind(this)
    this.stopObserve = this.stopObserve.bind(this)
    this.callBack = this.callBack.bind(this)

    if (
      typeof window !== 'undefined' &&
      typeof IntersectionObserver !== 'undefined' &&
      lazyLoading
    ) {
      this.observer = new IntersectionObserver(this.callBack, {
        rootMargin: '300px',
        threshold: 0.1,
      })
    }
  }

  componentDidMount() {
    try {
      Cookiebot.runScripts()
    } catch (error) {
      console.error(error)
    }

    this.startObserve()
  }

  componentDidUpdate() {
    this.startObserve()
  }

  componentWillUnmount() {
    this.stopObserve()
  }

  startObserve() {
    this.stopObserve()
    if (this.observer && this.lazyComponent) {
      this.observer.observe(this.lazyComponent)
    }
  }

  stopObserve() {
    if (this.observer && this.lazyComponent) {
      this.observer.unobserve(this.lazyComponent)
    }
  }

  callBack(entries, observer) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.setState({visible: true})
      }
    })
  }

  render() {
    const {content, size} = this.props

    if (!(content._i18nCL && content._i18nCL.vimeoId)) {
      return null
    }
    const i18n = content._i18nCL

    // let iFrameMarkup = <iframe src={'https://player.vimeo.com/video/' + i18n.vimeoId} />

    // if (!this.state.visible && lazyLoading) {
    //   iFrameMarkup = (
    //     <p
    //       ref={(input) => {
    //         this.lazyComponent = input
    //       }}
    //     >
    //       loading
    //     </p>
    //   )
    // }

    return (
      <div className="block-vimeo">
        <div className="aspectRatio">
          <div className="cookieconsent-optout-marketing">
            <div className="cookieconsent-optout-marketing-custom">
              <h3>
                <a href="javascript:Cookiebot.renew()">
                  <FormattedMessage
                    id="cookiePolicy.acceptMarketing"
                    defaultMessage="Please accept marketing cookies to see this content."
                  />
                </a>
              </h3>
            </div>
          </div>
          <iframe
            data-cookieconsent="marketing"
            data-cookieblock-src={'https://player.vimeo.com/video/' + i18n.vimeoId}
          />
        </div>
      </div>
    )
  }
}

ModBlockVimeo.propTypes = {
  content: PropTypes.object.isRequired,
  size: PropTypes.object.isRequired,
}
