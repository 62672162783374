import React from 'react'
import ModBlockEvents from 'components/blocks/ModBlockEvents'
import {addCurrentLangRecursive, leftPad} from 'common/DDUtil'
import {getCurrentLanguageOrFallBackByPath} from 'common/Languages'
import {replaceMediaDomainRecursive} from 'caas/CaasHelperTools'
import {tags} from 'vo/VoConfig'
import CaasApi from 'caas/CaasApi'
import {injectIntl} from 'react-intl'
import PropTypes from 'prop-types'

class ModBlockFilterableEvents extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      events: null,
      filterYear: Number(new Date().getFullYear()),
      sort: 'desc',
    }

    this.onChangeFilter = this.onChangeFilter.bind(this)
    this.onChangeOrder = this.onChangeOrder.bind(this)
    this.fetchEventsMod = this.fetchEventsMod.bind(this)

    const {formatMessage} = this.props.intl

    this.messages = {
      descending: {
        translation: formatMessage({
          id: 'blockFilterableEvents.descending',
          defaultMessage: 'Descending',
        }),
      },
      ascending: {
        translation: formatMessage({
          id: 'blockFilterableEvents.ascending',
          defaultMessage: 'Ascending',
        }),
      },
    }
  }

  componentDidMount() {
    this.fetchEventsMod().catch(function (error) {
      console.error(error)
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.filterYear !== this.state.filterYear) {
      this.fetchEventsMod().catch(function (error) {
        console.error(error)
      })
    }
  }

  onChangeFilter(event) {
    this.setState({
      filterYear: Number(event.target.value),
    })
  }

  onChangeOrder(event) {
    this.setState({
      sort: event.target.value,
    })
  }

  /**
   * fetch events and return the result. events may presented on various websites. therefore not contain internal links
   */
  async fetchEventsMod() {
    const {content, pathname, voConfig} = this.props
    const after = `${this.state.filterYear + 1}-01-01T00:00:00+${leftPad(
      new Date().getTimezoneOffset() / -60,
      2,
      '0'
    )}:00`
    const before = `${this.state.filterYear - 1}-12-31T23:59:59+${leftPad(
      new Date().getTimezoneOffset() / -60,
      2,
      '0'
    )}:00`

    const query = {
      resolveAllRefs: {
        filter: {
          value: {
            mapList: {
              value: {
                referrers: {
                  of: {
                    newRef: {
                      model: {
                        tag: tags.blockEventsView,
                      },
                      id: content._id,
                    },
                  },
                  in: {
                    tag: tags.blockEvents,
                  },
                },
              },
              expression: {
                get: {
                  id: {},
                },
              },
            },
          },
          expression: {
            and: [
              {
                before: [
                  {
                    field: {
                      name: 'date',
                      value: {
                        id: {},
                      },
                    },
                  },
                  {
                    newDateTime: after,
                  },
                ],
              },
              {
                after: [
                  {
                    field: {
                      name: 'date',
                      value: {
                        id: {},
                      },
                    },
                  },
                  {
                    newDateTime: before,
                  },
                ],
              },
            ],
          },
        },
      },
    }

    const {caasEndpoint, caasDb, user, pwd} = voConfig.publicConfig
    const currentLanguage = getCurrentLanguageOrFallBackByPath(pathname)
    const caasApi = new CaasApi(caasEndpoint, caasDb, user, pwd)
    let result = await caasApi.query(query)
    replaceMediaDomainRecursive(voConfig, result)
    addCurrentLangRecursive(result, currentLanguage)
    result = result.sort(function (a, b) {
      if (new Date(a.date).getTime() < new Date(b.date).getTime()) {
        return -1
      }
      if (new Date(a.date).getTime() > new Date(b.date).getTime()) {
        return 1
      }
      return 0
    })
    this.setState({events: result})
  }

  render() {
    let html = null
    const {pathname, showEventsDetail} = this.props

    if (this.state && this.state.events) {
      html = this.state.events.map(function (item, index) {
        return (
          <ModBlockEvents
            key={index}
            content={item}
            pathname={pathname}
            showEventsDetail={showEventsDetail}
          />
        )
      })
    }

    if (html && this.state.sort === 'desc') {
      html = html.reverse()
    }

    let yearFilter = []
    for (let currentYear = Number(new Date().getFullYear()); currentYear >= 2015; currentYear--) {
      yearFilter.push(
        <option key={currentYear} value={currentYear}>
          {currentYear}
        </option>
      )
    }

    return (
      <div className="block-filterable-events">
        <div className="filter">
          <div>
            <div>
              <select onChange={this.onChangeFilter}>{yearFilter}</select>
            </div>
          </div>
          <div>
            <div>
              <select onChange={this.onChangeOrder}>
                <option value="desc">{this.messages.descending.translation}</option>
                <option value="asc">{this.messages.ascending.translation}</option>
              </select>
            </div>
          </div>
        </div>
        <div>{html}</div>
      </div>
    )
  }
}

ModBlockFilterableEvents.propTypes = {
  pathname: PropTypes.string.isRequired,
  navigationTree: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
  voConfig: PropTypes.object.isRequired,
  showEventsDetail: PropTypes.func.isRequired,
}

export default injectIntl(ModBlockFilterableEvents)
