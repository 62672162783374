import React from 'react'
import ModRichText from 'components/common/ModRichText'
import ModImgTag from 'components/common/ModImgTag'
import PropTypes from 'prop-types'

export default class ModBlockTwoImagesText extends React.Component {
  render() {
    const {content, pathname, bgColor} = this.props

    if (!content._i18nCL) {
      return null
    }
    const i18n = content._i18nCL

    let text = null
    if (i18n.text) {
      text = <ModRichText richText={i18n.text} pathname={pathname} />
    }

    return (
      <div className={'block-two-images-text bg-' + bgColor}>
        <div className="block-two-images-text-images">
          <ModImgTag imgObject={content.imageLeft} width={550} height={330} />
          <ModImgTag imgObject={content.imageRight} width={550} height={330} />
        </div>
        <div className="l-centered-content">{text}</div>
      </div>
    )
  }
}

ModBlockTwoImagesText.propTypes = {
  content: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
}
