import React from 'react'
import {createReactComponents} from 'common/DraftJsToReact'
import {convertFromRaw} from 'draft-js'
import PropTypes from 'prop-types'

export default class ModRichText extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {richText, pathname} = this.props
    return createReactComponents(convertFromRaw(richText), pathname)
  }
}

ModRichText.propTypes = {
  richText: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
}
