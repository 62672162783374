import * as React from 'react'
import PropTypes from 'prop-types'

export const Arrow = require('!desvg-loader/react!svg-loader!../../static/img/svg/but_arrow.svg')
export const ArrowDown = require('!desvg-loader/react!svg-loader!../../static/img/svg/but_arrow_down.svg')
export const ArrowHomeSlider = require('!desvg-loader/react!svg-loader!../../static/img/svg/but_arrow_home_slider.svg')
export const ArrowSlider = require('!desvg-loader/react!svg-loader!../../static/img/svg/but_arrow_slider.svg')
export const Burger = require('!desvg-loader/react!svg-loader!../../static/img/svg/but_burger.svg')
export const Close = require('!desvg-loader/react!svg-loader!../../static/img/svg/but_close.svg')
export const CloseFramed = require('!desvg-loader/react!svg-loader!../../static/img/svg/but_close_framed.svg')
export const DoubleArrow = require('!desvg-loader/react!svg-loader!../../static/img/svg/but_double_arrow.svg')
export const LinkDoc = require('!desvg-loader/react!svg-loader!../../static/img/svg/but_link_doc.svg')
export const LinkExternalFilled = require('!desvg-loader/react!svg-loader!../../static/img/svg/but_link_external_filled.svg')
export const LinkFilled = require('!desvg-loader/react!svg-loader!../../static/img/svg/but_link_filled.svg')
export const LinkImage = require('!desvg-loader/react!svg-loader!../../static/img/svg/but_link_img.svg')

function nodeForIconName(icon) {
  switch (icon) {
    case Arrow:
      return <Arrow />
    case ArrowDown:
      return <ArrowDown />
    case ArrowHomeSlider:
      return <ArrowHomeSlider />
    case ArrowSlider:
      return <ArrowSlider />
    case Burger:
      return <Burger />
    case Close:
      return <Close />
    case CloseFramed:
      return <CloseFramed />
    case DoubleArrow:
      return <DoubleArrow />
    case LinkDoc:
      return <LinkDoc />
    case LinkExternalFilled:
      return <LinkExternalFilled />
    case LinkFilled:
      return <LinkFilled />
    case LinkImage:
      return <LinkImage />
  }
}

export default class ModSvg extends React.Component {
  render() {
    return <div className="icon-home icon">{nodeForIconName(this.props.icon)}</div>
  }
}

ModSvg.propTypes = {
  icon: PropTypes.any.isRequired,
}
