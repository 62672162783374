import React from 'react'
import ModRichText from 'components/common/ModRichText'
import {draftJsHasContent} from 'common/DDUtil'
import PropTypes from 'prop-types'

export default class ModBlockRichText extends React.Component {
  render() {
    const {content, pathname, bgColor} = this.props

    if (!(content._i18nCL && draftJsHasContent(content._i18nCL.text))) {
      return null
    }

    const i18n = content._i18nCL

    return (
      <div className={'block-rich-text bg-' + bgColor}>
        <div className="l-centered-content typo-h5">
          <ModRichText richText={i18n.text} pathname={pathname} />
        </div>
      </div>
    )
  }
}

ModBlockRichText.propTypes = {
  content: PropTypes.object.isRequired,
  bgColor: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
}
