import React from 'react'
import ModRichText from 'components/common/ModRichText'
import ModAnchorTag from 'components/common/ModAnchorTag'
import ModImgTag from 'components/common/ModImgTag'
import PropTypes from 'prop-types'

export default class ModBlockImageText extends React.Component {
  render() {
    const {content, currentLanguage, pathname, bgColor} = this.props

    const list = content.list.map(function (item, index) {
      if (item.i18n.hasOwnProperty(currentLanguage)) {
        const i18n = item.i18n[currentLanguage]
        return (
          <div key={index}>
            <ModAnchorTag linkObject={item.imageLink} pathname={pathname}>
              <ModImgTag imgObject={item.image} width={550} height={330} />
            </ModAnchorTag>

            <div className="block-image-text-container">
              <div className="content">
                <h2 className="typo-h6">{i18n.title}</h2>
                <ModRichText richText={i18n.text} pathname={pathname} />
              </div>
            </div>
          </div>
        )
      }
      return null
    })

    return <div className={'block-image-text bg-' + bgColor}>{list}</div>
  }
}

ModBlockImageText.propTypes = {
  content: PropTypes.object.isRequired,
  currentLanguage: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
}
