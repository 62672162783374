import React from 'react'
import ModRichText from 'components/common/ModRichText'
import ModImgTag from 'components/common/ModImgTag'
import ModAnchorTag from '../common/ModAnchorTag'
import {FormattedMessage} from 'react-intl'
import PropTypes from 'prop-types'

export default class ModBlockTeaser extends React.Component {
  componentDidMount() {
    try {
      Cookiebot.runScripts()
    } catch (error) {
      console.error(error)
    }
  }

  render() {
    const {content, currentLanguage, pathname} = this.props

    const teaserList = this.props.content.list.map(function (item, index) {
      if (!(item._i18nCL && item._i18nCL.title)) {
        return null
      }
      const i18n = item._i18nCL

      let content = null

      const logo = item.logo ? (
        <div className="block-teaser-logo">
          <ModImgTag imgObject={item.logo} width={370} height={225} />
        </div>
      ) : null

      if (i18n.vimeoId) {
        content = (
          <div>
            <div className="block-teaser-image teaser-video">
              <div className="aspectRatio">
                <div className="cookieconsent-optout-marketing">
                  <div className="cookieconsent-optout-marketing-custom">
                    <h3>
                      <a href="javascript:Cookiebot.renew()">
                        <FormattedMessage
                          id="cookiePolicy.acceptMarketing"
                          defaultMessage="Please accept marketing cookies to see this content."
                        />
                      </a>
                    </h3>
                  </div>
                </div>
                <iframe
                  data-cookieconsent="marketing"
                  data-cookieblock-src={'https://player.vimeo.com/video/' + i18n.vimeoId}
                />
              </div>
            </div>
            <div
              className={
                'block-teaser-text-container' +
                (item.logo && item.logo.image ? ' is-hidden-mobile' : '')
              }
            >
              <div className={'content'}>
                <div>
                  <h6 className="block-teaser-title typo-h12">{i18n.title}</h6>
                  <div className="typo-h5">
                    <ModRichText richText={i18n.text} pathname={pathname} />
                  </div>
                </div>
              </div>
            </div>
            {logo}
          </div>
        )
      } else if (item.image) {
        content = (
          <div>
            <div className="block-teaser-image">
              <ModImgTag imgObject={item.image} width={465} height={225} />
            </div>
            <div
              className={
                'block-teaser-text-container' +
                (item.logo && item.logo.image ? ' is-hidden-mobile' : '')
              }
            >
              <div className={'content'}>
                <div>
                  <h6 className="block-teaser-title typo-h12">{i18n.title}</h6>
                  <div className="typo-h5">
                    <ModRichText richText={i18n.text} pathname={pathname} />
                  </div>
                </div>
              </div>
            </div>
            {logo}
          </div>
        )
      }

      return (
        <div key={index} className="block-teaser-container">
          <ModAnchorTag linkObject={item.link} pathname={pathname}>
            {content}
          </ModAnchorTag>
        </div>
      )
    })
    return <div className={'block-teaser bg-' + this.props.bgColor}>{teaserList}</div>
  }
}

ModBlockTeaser.propTypes = {
  content: PropTypes.object.isRequired,
  currentLanguage: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
}
