import React from 'react'
import ModImgTag from 'components/common/ModImgTag'
import PropTypes from 'prop-types'

export default class ModBlockPoster extends React.Component {
  render() {
    const {content, bgColor} = this.props

    if (!content._i18nCL) {
      return null
    }
    const i18n = content._i18nCL

    return (
      <div className={'block-poster bg-' + bgColor}>
        <h2 className="block-post-title typo-h13 l-centered-content">{i18n.quote}</h2>
        <ModImgTag imgObject={content.image} width={520} height={520} />
      </div>
    )
  }
}

ModBlockPoster.propTypes = {
  content: PropTypes.object.isRequired,
  bgColor: PropTypes.string.isRequired,
}
