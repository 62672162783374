import React from 'react'
import ReactDOM from 'react-dom'
import ModAnchorTag from 'components/common/ModAnchorTag'
import ModImgTag from 'components/common/ModImgTag'
import ModVimeoPlayer from 'components/common/ModVimeoPlayer'
import ModIdangerousSwiper from 'components/common/ModIdangerousSwiper'
import PropTypes from 'prop-types'

const MAX_IMG_SIZE = 1000

export default class ModBlockHome extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeIndex: 0,
      lastIndex: -1,
    }

    this.blockHome = null
    this.onSlide = this.onSlide.bind(this)
    this.onScrollDown = this.onScrollDown.bind(this)
  }

  onSlide(activity, activeIndex) {
    if (this.state.activeIndex !== activeIndex && !activity) {
      this.setState({
        lastIndex: this.state.activeIndex,
        activeIndex: activeIndex,
      })
    }
  }

  onScrollDown() {
    const divBlockHome = ReactDOM.findDOMNode(this.blockHome)
    window.scrollTo(0, divBlockHome.clientHeight)
  }

  componentDidMount() {
    this.timeoutId = setTimeout(
      function () {
        this.setState({show: true})
      }.bind(this),
      1000
    )
  }

  render() {
    const {content, pathname} = this.props

    if (!(content && content.list.length > 0)) {
      return null
    }

    const vimeoId = content.list[this.state.activeIndex].vimeoId || null

    let spots = []
    let homeSlider = content.list.map((item, index) => {
      let vimeoPlayer = null
      let cssAnimation = ''

      let className = index === this.state.activeIndex ? 'spot active' : 'spot'
      if (item.spotLink) {
        spots.push(
          <div key={'spot' + index} className={className}>
            <ModAnchorTag linkObject={item.spotLink} pathname={pathname}>
              <ModImgTag imgObject={item.spotImage} />
            </ModAnchorTag>
          </div>
        )
      } else {
        spots.push(
          <div key={'spot' + index} className={className}>
            <ModImgTag imgObject={item.spotImage} />
          </div>
        )
      }

      if (index === this.state.activeIndex) {
        if (vimeoId) {
          vimeoPlayer = <ModVimeoPlayer vimeoId={vimeoId} isBackgroundVideo={true} />
        } else {
          cssAnimation = 'css-animation'
        }
      }
      return (
        <div
          key={index}
          className={this.state.show ? 'swiper-slide ' + cssAnimation : 'swiper-slide '}
        >
          <div className="wrapper">
            <ModImgTag
              imgObject={item.image}
              width={MAX_IMG_SIZE}
              height={MAX_IMG_SIZE}
              transformation="c_limit"
            />
            {vimeoPlayer}
          </div>
        </div>
      )
    })

    return (
      <div className="block-home" ref={(ref) => (this.blockHome = ref)}>
        <div className="bottom">
          <div className="bottom-button">
            <button aria-label="scroll down" onClick={this.onScrollDown}>
              <img className="btn" src={require('static/img/btn-home-godown.png')} />
              <img className="arrow" src={require('static/img/btn-home-godown-arrow.png')} />
            </button>
          </div>
        </div>
        <div className="bottom-bar" />
        <div className="spots">{spots}</div>
        <ModIdangerousSwiper
          id={content._id}
          hasNavigation={true}
          hasHomeNavigation={true}
          onSlide={this.onSlide}
          options={{autoplay: 10000}}
        >
          {homeSlider}
        </ModIdangerousSwiper>
      </div>
    )
  }
}

ModBlockHome.propTypes = {
  content: PropTypes.shape({
    list: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.object,
        vimeoId: PropTypes.string,
        spotImage: PropTypes.object,
        spotImageAltText: PropTypes.string,
        spotLink: PropTypes.object,
      })
    ).isRequired,
  }).isRequired,
  pathname: PropTypes.string.isRequired,
}
