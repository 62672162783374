export const mapStyles = [
  {
    stylers: [{hue: '#bbe4e8'}, {saturation: -40}],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{lightness: 100}, {visibility: 'simplified'}],
  },
  {
    featureType: 'road',
    elementType: 'labels',
    stylers: [{visibility: 'off'}],
  },
]
