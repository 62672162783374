import React from 'react'
import NavLink from 'components/common/NavLink'
import ModAnchorTag from 'components/common/ModAnchorTag'
import {hasContent, addClassToElement, removeClassFromElement} from 'common/DDUtil'
import {isArticleActiveNow, searchNavigationNodeByUrl} from 'caas/CaasHelperTools'
import ModSvg, {IconName} from 'components/common/ModSvg'
import {FormattedMessage} from 'react-intl'
import {Burger, Close} from './common/ModSvg'
import PropTypes from 'prop-types'

export default class ModNavigation extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      naviOpen: false,
      openNodeId: null,
    }

    this.toggleSubNav = this.toggleSubNav.bind(this)
    this.toggleNavigation = this.toggleNavigation.bind(this)
    this.closeNavigation = this.closeNavigation.bind(this)
    this.createNavigationNode = this.createNavigationNode.bind(this)
    this.createLanguageNodes = this.createLanguageNodes.bind(this)
    this.removeLanguageNavigationNode = this.removeLanguageNavigationNode.bind(this)
  }

  setStateBy(replaceObjects) {
    this.setState(Object.assign({}, this.state, replaceObjects))

    // Set App Class if menu is open
    if (typeof window !== 'undefined') {
      let app = window.document.getElementById('app')
      let body = document.getElementsByTagName('BODY')[0]
      if (!this.state.naviOpen) {
        addClassToElement(app, 'navigation-open')
        addClassToElement(body, 'overflow-hidden')
      } else {
        removeClassFromElement(app, 'navigation-open')
        removeClassFromElement(body, 'overflow-hidden')
      }
    }
  }

  toggleSubNav(e) {
    const navButton = e.target
    if (navButton) {
      let id = navButton.dataset.id
      if (id === this.state.openNodeId) {
        id = null
      }

      this.setStateBy({
        openNodeId: id,
      })
    }
  }

  toggleNavigation(e) {
    this.setStateBy({
      naviOpen: !this.state.naviOpen,
      openNodeId: null,
    })
  }

  closeNavigation(e) {
    if (this.state.naviOpen === true) {
      this.setStateBy({
        naviOpen: false,
      })
    }
  }

  createNavigationNode(nodes, isTopNav, pathname, currentLanguage, activeNode) {
    function isNodeVisible(node, i18n) {
      if (!i18n.showInMenu) {
        return false
      }
      if (hasContent(node.children)) {
        return true
      }
      if (node.content) {
        return isArticleActiveNow(node.content, currentLanguage)
      }
      return true
    }

    if (!(nodes instanceof Array) || nodes.length < 1) {
      return null
    }
    let d = nodes.reduce((prevItem, item, index) => {
      if (!item.i18n.hasOwnProperty(currentLanguage)) {
        return prevItem
      }
      const i18n = item.i18n[currentLanguage]
      if (!isNodeVisible(item, i18n)) {
        return prevItem
      }

      if (isTopNav) {
        const hasActiveChild = item.children.find((item) => {
          return activeNode && item.id === activeNode.id
        })

        let openNode = this.state.openNodeId === item.id
        if (openNode === false && hasActiveChild && !this.state.openNodeId) {
          openNode = true
        }

        const validChild = item.children.find((node) => {
          if (node.i18n.hasOwnProperty(currentLanguage)) {
            return node.i18n[currentLanguage].showInMenu
          }
        })
        if (!validChild) {
          prevItem.push(
            <li key={index} className="navigation-item-container">
              <div data-id={item.id} className="navigation-top">
                <div data-id={item.id} className="navigation-arrow icon icon-but_arrow_right" />
                <NavLink to={i18n.relativeUrl} onClick={this.closeNavigation}>
                  <span data-id={item.id}>{i18n.label}</span>
                </NavLink>
              </div>
            </li>
          )
        } else {
          prevItem.push(
            <li key={index} className={'navigation-item-container' + (openNode ? ' is-open' : '')}>
              <div
                className={'navigation-top' + (openNode ? ' is-open' : '')}
                onClick={this.toggleSubNav}
                data-id={item.id}
              >
                <div data-id={item.id} className="navigation-arrow icon icon-but_arrow_right" />
                <span data-id={item.id}>{i18n.label}</span>
              </div>
              {this.createNavigationNode(item.children, false, null, currentLanguage, activeNode)}
            </li>
          )
        }
      } else {
        prevItem.push(
          <li key={index}>
            <NavLink
              className="icon icon-but_arrow_subnav_black"
              to={i18n.relativeUrl}
              onClick={this.closeNavigation}
            >
              <span>{i18n.label}</span>
            </NavLink>
          </li>
        )
      }

      return prevItem
    }, [])

    return <ul className={isTopNav ? 'navigation-nodes' : 'navigation-sub-nodes'}>{d}</ul>
  }

  createLanguageNodes(nodes, rootNode, currentLanguage, pathname) {
    let languageNodes = []
    for (let langKey in rootNode.i18n) {
      if (rootNode.i18n.hasOwnProperty(langKey)) {
        let lang = rootNode.i18n[langKey]
        if (hasContent(lang.label)) {
          languageNodes.push(
            <li key={langKey} className="language">
              <NavLink to={lang.relativeUrl} onClick={this.closeNavigation}>
                {lang.label}
              </NavLink>
            </li>
          )
        }
      }
    }

    let metaNavigation = []
    if (nodes) {
      nodes.map((item, index) => {
        let label = ''
        if (item.i18n.hasOwnProperty(currentLanguage)) {
          const i18n = item.i18n[currentLanguage]
          label = i18n.title
        }

        metaNavigation.push(
          <li key={index} onClick={this.closeNavigation}>
            <ModAnchorTag linkObject={item.link} pathname={pathname}>
              {label}
            </ModAnchorTag>
          </li>
        )
      })
    }

    return (
      <div className="navigation-meta">
        <FormattedMessage id="navigation.language" defaultMessage="Language" />:
        <ul className="navigation-languages">{languageNodes}</ul>
        <ul className="navigation-meta-links">{metaNavigation}</ul>
      </div>
    )
  }

  removeLanguageNavigationNode(nodes, currentLanguage) {
    if (!(nodes instanceof Array)) {
      return null
    }
    for (let key in nodes) {
      if (nodes.hasOwnProperty(key)) {
        let node = nodes[key]
        if (node.slug === currentLanguage) {
          return node
        }
      }
    }
  }

  render() {
    const {navigationTree, currentLanguage, pathname, config} = this.props

    if (!(navigationTree && navigationTree.children && hasContent(currentLanguage))) {
      return null
    }

    const activeNode = searchNavigationNodeByUrl(navigationTree, pathname, currentLanguage)
    const langNavigation = this.createLanguageNodes(
      config.websiteSettings.metaNavigation,
      navigationTree,
      currentLanguage,
      pathname
    )
    const mainNavigation = this.createNavigationNode(
      navigationTree.children,
      true,
      pathname,
      currentLanguage,
      activeNode
    )

    return (
      <div id="navigation" className={this.state.naviOpen ? 'is-expanded' : ''}>
        <div className="navigation-header">
          <button
            aria-label="menu button"
            className="navigation-burger icon"
            onClick={this.toggleNavigation}
          >
            <ModSvg icon={Burger} />
          </button>
          <button
            aria-label="close menu"
            className="navigation-close icon"
            onClick={this.toggleNavigation}
          >
            <ModSvg icon={Close} />
          </button>
          <NavLink to={'/' + currentLanguage} onClick={this.closeNavigation}>
            <img
              src={require('static/img/marche_logo_negative.png')}
              className="navigation-logo"
              title="home"
              alt="goto home"
            />
          </NavLink>
        </div>
        <div className="navigation-wrapper">
          <div className="navigation-content">
            <div className="navigation-main">{mainNavigation}</div>
            <div className="navigation-meta">{langNavigation}</div>
          </div>
        </div>
      </div>
    )
  }
}

ModNavigation.propTypes = {
  currentLanguage: PropTypes.string.isRequired,
  navigationTree: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
  config: PropTypes.object.isRequired,
}
