import React from 'react'
import PropTypes from 'prop-types'

export default class ModBlockTitle extends React.Component {
  render() {
    const {content, bgColor} = this.props

    if (!(content._i18nCL && content._i18nCL.title)) {
      return null
    }
    const i18n = content._i18nCL

    let subTitle = i18n.subtitle ? (
      <p className="block-title-subtitle typo-h4">{i18n.subtitle}</p>
    ) : null
    const a = i18n.anchor ? (
      <a id={'anchor_' + i18n.anchor.toLowerCase()} name={i18n.anchor.toLowerCase()} />
    ) : null

    return (
      <div className={'block-title bg-' + bgColor}>
        <div className="block-title-bg" />
        {a}
        <div className="block-title-triangle">
          <h2 className="typo-h3">{i18n.title}</h2>
          {subTitle}
        </div>
      </div>
    )
  }
}

ModBlockTitle.propTypes = {
  content: PropTypes.shape({
    _i18nCL: PropTypes.object.isRequired,
  }).isRequired,
  bgColor: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
}
