import React from 'react'
import ModBlockTitle from 'components/blocks/ModBlockTitle'
import ModBlockSpacer from 'components/blocks/ModBlockSpacer'
import ModBlockTeaser from 'components/blocks/ModBlockTeaser'
import ModBlockImageText from 'components/blocks/ModBlockImageText'
import ModBlockTwoImagesText from 'components/blocks/ModBlockTwoImagesText'
import ModBlockHeader from 'components/blocks/ModBlockHeader'
import ModBlockPoster from 'components/blocks/ModBlockPoster'
import ModBlockRichText from 'components/blocks/ModBlockRichText'
import ModBlockTeaserSpot from 'components/blocks/ModBlockTeaserSpot'
import ModBlockSlider from 'components/blocks/ModBlockSlider'
import ModBlockLocations from 'components/blocks/ModBlockLocations'
import ModBlockInstagramLightWidget from 'components/blocks/ModBlockInstagramLightWidget'
import ModBlockVimeo from 'components/blocks/ModBlockVimeo'
import ModBlockImage from 'components/blocks/ModBlockImage'
import ModBlockGrid from 'components/blocks/ModBlockGrid'
import ModBlockHome from 'components/blocks/ModBlockHome'
import ModBlockTestimonials from 'components/blocks/ModBlockTestimonials'
import ModEditBlock from 'components/common/ModEditBlock'
import ModBlockEvents from 'components/blocks/ModBlockEvents'
import ModBlockFilterableEvents from 'components/blocks/ModBlockFilterableEvents'
import ModImgTag from 'components/common/ModImgTag'
import {injectIntl} from 'react-intl'
import {shuffleArray} from 'common/DDUtil'
import PropTypes from 'prop-types'

/**
 *
 */
class ModTemplateBlockList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      eventsDetail: null,
    }

    this.showEventsDetail = this.showEventsDetail.bind(this)
  }

  showEventsDetail(state) {
    this.setState({eventsDetail: state})
  }

  componentWillReceiveProps(nextProps) {
    this.setState({eventsDetail: null})
  }

  render() {
    const {
      templateData,
      config,
      currentLanguage,
      pathname,
      caasHelper,
      navigationNodeId,
      navigationTree,
      showEventsDetail,
    } = this.props

    const blockList = templateData.contents.map((_item, index) => {
      const typeKey = Object.keys(_item)[0]
      const content = _item[typeKey]
      const {block} = content
      return {typeKey, block, content}
    })

    let html = null
    if (this.state.eventsDetail) {
      const {formatDate, formatMessage} = this.props.intl
      const {content} = this.state.eventsDetail
      html = []
      const titleProp = {
        _i18nCL: {
          title: formatMessage({id: 'eventsDetail.mediaRelease', defaultMessage: 'media release'}),
          subtitle: formatDate(new Date(content.date), {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }),
          anchor: '',
        },
      }
      const headerProp = {
        image: {
          cloudinaryMedia: {
            url: require('static/img/mic.jpg'),
          },
          i18n: {
            de: {altText: 'mic', title: 'mic'},
            en: {altText: 'mic', title: 'mic'},
          },
          _i18nCL: {altText: 'mic', title: 'mic'},
        },
        _i18nCL: {
          title: formatMessage({id: 'eventsDetail.title', defaultMessage: 'media releases'}),
          subtitle: formatMessage({id: 'eventsDetail.subtitle', defaultMessage: 'top informed'}),
        },
      }
      html.push(
        <ModBlockHeader key="0" content={headerProp} voConfig={config} pathname={pathname} />
      )
      html.push(<ModBlockTitle key="1" content={titleProp} bgColor="grey" pathname={pathname} />)
      html.push(
        <ModBlockTwoImagesText key="2" content={content} bgColor="grey" pathname={pathname} />
      )
      if (typeof window !== 'undefined') {
        window.scrollTo(0, 0)
      }
    } else {
      html = blockList.reduce((itemList, item, index) => {
        const {typeKey, block, content} = item

        let blockMarkup = null
        switch (typeKey) {
          case 'blockHeader':
            blockMarkup = (
              <ModBlockHeader
                key={index}
                content={block}
                blocks={blockList}
                voConfig={config}
                pathname={pathname}
              />
            )
            break
          case 'blockCorporateHome':
            blockMarkup = <ModBlockHome key={index} pathname={pathname} content={block} />
            break
          case 'blockTitle':
            blockMarkup = (
              <ModBlockTitle
                key={index}
                content={block}
                bgColor={content.bgColor}
                pathname={pathname}
              />
            )
            break
          case 'blockRichText':
            blockMarkup = (
              <ModBlockRichText
                key={index}
                content={block}
                bgColor={content.bgColor}
                pathname={pathname}
              />
            )
            break
          case 'blockImageText':
            blockMarkup = (
              <ModBlockImageText
                key={index}
                content={block}
                currentLanguage={currentLanguage}
                bgColor={content.bgColor}
                pathname={pathname}
              />
            )
            break
          case 'blockTwoImagesText':
            blockMarkup = (
              <ModBlockTwoImagesText
                key={index}
                content={block}
                bgColor={content.bgColor}
                pathname={pathname}
              />
            )
            break
          case 'blockSpacer':
            blockMarkup = <ModBlockSpacer key={index} content={block} pathname={pathname} />
            break
          case 'blockTeaser':
            blockMarkup = (
              <ModBlockTeaser
                key={index}
                content={block}
                pathname={pathname}
                bgColor={content.bgColor}
                currentLanguage={currentLanguage}
              />
            )
            break
          case 'blockSlider':
            if (block.list.length === 1) {
              // If there is just one item in the list, display an simple Image Block
              blockMarkup = (
                <ModBlockImage
                  key={index}
                  content={{image: block.list[0].image}}
                  pathname={pathname}
                />
              )
            } else {
              blockMarkup = (
                <ModBlockSlider key={index} content={block} index={index} pathname={pathname} />
              )
            }
            break
          case 'blockPoster':
            blockMarkup = (
              <ModBlockPoster
                key={index}
                content={block}
                bgColor={content.bgColor}
                pathname={pathname}
              />
            )
            break
          case 'blockTeaserSpot':
            blockMarkup = (
              <ModBlockTeaserSpot
                key={index}
                content={block}
                bgColor={content.bgColor}
                pathname={pathname}
              />
            )
            break
          case 'blockTestimonials':
            blockMarkup = (
              <ModBlockTestimonials
                key={index}
                content={block}
                bgColor={content.bgColor}
                pathname={pathname}
              />
            )
            break
          case 'blockGrid':
            blockMarkup = <ModBlockGrid key={index} content={block} pathname={pathname} />
            break
          case 'blockLocations':
            blockMarkup = (
              <ModBlockLocations
                key={index}
                content={block}
                voConfig={config}
                currentLanguage={currentLanguage}
                pathname={pathname}
              />
            )
            break
          case 'blockInstagramLightWidget':
            blockMarkup = (
              <ModBlockInstagramLightWidget key={index} content={block} pathname={pathname} />
            )
            break
          case 'blockVimeo':
            blockMarkup = (
              <ModBlockVimeo key={index} content={block} pathname={pathname} size={content.size} />
            )
            break
          case 'blockEventsView':
            blockMarkup = (
              <ModBlockFilterableEvents
                key={index}
                voConfig={config}
                content={block}
                navigationTree={navigationTree}
                pathname={pathname}
                showEventsDetail={this.showEventsDetail}
              />
            )
            break
          case 'blockEvents':
            blockMarkup = (
              <ModBlockEvents
                key={index}
                content={block}
                pathname={pathname}
                showEventsDetail={this.showEventsDetail}
              />
            )
            break
          default:
            blockMarkup = null
        }

        if (config.editMode && blockMarkup) {
          itemList.push(
            <ModEditBlock key={'_' + index} entityObject={block} title={typeKey} config={config} />
          )
        }
        itemList.push(blockMarkup)
        return itemList
      }, [])
    }

    // *****************************************************************************************************************
    // bg illus
    const BG_IMAGES = [
      require('static/img/bg_fish.png'),
      require('static/img/bg_pineapple.png'),
      require('static/img/bg_poppy.png'),
      require('static/img/bg_balloon.png'),
      require('static/img/bg_broccoli.png'),
      require('static/img/bg_parrott.png'),
    ]

    const BG_NAME = ['fish', 'pineapple', 'poppy', 'balloon', 'broccoli', 'parrott']

    let shuffle = [0, 1, 2, 3, 4, 5]
    shuffleArray(shuffle)
    let flipFlop = Math.random() > 0.5
    let illus = []

    for (let i = 0; i < 12; i++) {
      let index = shuffle[i % BG_IMAGES.length]
      flipFlop = !flipFlop
      let className = flipFlop ? 'left' : 'right'
      className += ' ' + BG_NAME[shuffle[index]]
      illus.push(
        <div className="illu" key={i}>
          <ModImgTag imgObject={{cloudinaryMedia: {url: BG_IMAGES[shuffle[index]]}}} />
        </div>
      )
    }
    // *****************************************************************************************************************

    return (
      <div className="layout-article">
        <div id="bg-illus">
          <div id="illus-wrapper">{illus}</div>
        </div>
        {html}
      </div>
    )
  }
}

ModTemplateBlockList.propTypes = {
  templateData: PropTypes.object,
  config: PropTypes.object,
  currentLanguage: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  caasHelper: PropTypes.object.isRequired,
  navigationNodeId: PropTypes.string.isRequired,
  navigationTree: PropTypes.object.isRequired,
}

export default injectIntl(ModTemplateBlockList)
