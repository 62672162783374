import React from 'react'
import ModRichText from './common/ModRichText'
import {FormattedMessage} from 'react-intl'
import ModSvg, {Arrow} from './common/ModSvg'
import PropTypes from 'prop-types'

export default class ModCookiePolicy extends React.Component {
  constructor(props) {
    super(props)

    this.onOk = this.onOk.bind(this)
  }

  onOk(event) {
    const {setCookie} = this.props
    setCookie('cookie_consent_dismissed', true)
  }

  render() {
    if (!this.props.i18n) {
      return null
    }
    const {i18n, cookies, pathname} = this.props
    if (!cookies) {
      return null
    }
    if (cookies.cookie_consent_dismissed === true) {
      return null
    }
    return (
      <div className="cookie-policy">
        <div className="cookie-policy-wrapper">
          <ModRichText richText={i18n.cookiePolicy} pathname={pathname} />

          <div className="accept-cookie" onClick={this.onOk}>
            <FormattedMessage id="cookiePolicy.ok" defaultMessage="I agree" />
            <ModSvg icon={Arrow} />
          </div>
        </div>
      </div>
    )
  }
}

ModCookiePolicy.propTypes = {
  i18n: PropTypes.object.isRequired,
  setCookie: PropTypes.func.isRequired,
  cookies: PropTypes.object,
  pathname: PropTypes.string.isRequired,
}
