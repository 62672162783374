import React from 'react'
import PropTypes from 'prop-types'

export default class ModBlockSpacer extends React.Component {
  render() {
    const {content} = this.props
    var divStyle = {
      marginBottom: content.value + 'px',
    }
    return <div className="block-spacer bg-white" style={divStyle}></div>
  }
}

ModBlockSpacer.propTypes = {
  content: PropTypes.shape({
    value: PropTypes.number.isRequired,
  }).isRequired,
}
