import React from 'react'
import Swiper from 'swiper'
import ModSvg from 'components/common/ModSvg'
import {ArrowHomeSlider, ArrowSlider, DoubleArrow} from './ModSvg'
import PropTypes from 'prop-types'

export default class ModIdangerousSwiper extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeSlide: 1,
    }

    this.swiper = null
    this.destroySwiperComponents = this.destroySwiperComponents.bind(this)
    this.createSwiperComponents = this.createSwiperComponents.bind(this)
    this.onSliderSlide = this.onSliderSlide.bind(this)
  }

  componentWillUnmount() {
    this.destroySwiperComponents()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.children.length !== prevProps.children.length || !this.swiper) {
      this.createSwiperComponents()
    }
  }

  componentDidMount() {
    this.createSwiperComponents()
  }

  createSwiperComponents() {
    this.destroySwiperComponents()

    let params = {
      spaceBetween: 10,
      lazy: {
        loadPrevNext: true,
      },
    }
    if (this.props.options) {
      params = Object.assign(params, this.props.options)
    }
    if (this.props.hasNavigation) {
      params.navigation = {
        nextEl: '#' + this.props.id + ' .swiper-button-next',
        prevEl: '#' + this.props.id + ' .swiper-button-prev',
      }
    }
    if (this.props.hasPagination) {
      params.pagination = {
        el: '#' + this.props.id + ' .swiper-pagination',
        clickable: true,
      }
    }

    this.swiper = new Swiper('#' + this.props.id, params)

    this.swiper.on('slideChangeTransitionStart', () => {
      this.onSliderSlide(true)
    })
    this.swiper.on('sliderMove', () => {
      this.onSliderSlide(true)
    })
    this.swiper.on('slideChangeTransitionEnd', () => {
      this.onSliderSlide(false)
    })
  }

  onSliderSlide(activity) {
    if (this.state.activeSlide !== this.swiper.activeIndex + 1 && !activity) {
      this.setState({
        activeSlide: this.swiper.activeIndex + 1,
      })
    }
    if (this.props.onSlide) {
      this.props.onSlide(activity, this.swiper.activeIndex)
    }
  }

  destroySwiperComponents() {
    if (this.swiper) {
      this.swiper.off('onSlideChangeStart')
      this.swiper.off('onSliderMove')
      this.swiper.off('onSlideChangeEnd')
      this.swiper.destroy()
    }
  }

  addLeadingZeros(number, maxNumber) {
    let digit_count = maxNumber.toString().length
    if (digit_count < 2) digit_count = 2

    let s = number.toString()
    while (s.length < digit_count) {
      s = '0' + s
    }
    return s
  }

  render() {
    const {
      children,
      hasNavigation,
      hasHomeNavigation,
      hasFullScreen,
      hasPagination,
      maxPaginationCount,
      hasSlideNumber,
      id,
      textSlideshowBlock,
    } = this.props

    let swiperButton = null

    if (hasNavigation) {
      const naName = textSlideshowBlock ? 'text-swiper-buttons' : 'swiper-button-wrapper'
      const icons = hasHomeNavigation ? ArrowHomeSlider : ArrowSlider
      swiperButton = (
        <div className={naName}>
          <div className="swiper-button-next">
            <ModSvg icon={icons} />
          </div>
          <div className="swiper-button-prev">
            <ModSvg icon={icons} />
          </div>
        </div>
      )
    }

    return (
      <div className="swiper-container" id={id}>
        <div className="swiper-wrapper">{children}</div>
        {hasFullScreen ? (
          <div className="swiper-button-fullscreen">
            <ModSvg icon={DoubleArrow} />
          </div>
        ) : (
          ''
        )}
        {swiperButton}
        {hasPagination && children.length < maxPaginationCount ? (
          <div className="swiper-pagination" />
        ) : (
          ''
        )}
        {hasSlideNumber ? (
          <div className="swiper-slide-number">
            {this.addLeadingZeros(this.state.activeSlide, children.length)}/
            {this.addLeadingZeros(children.length, children.length)}
          </div>
        ) : (
          ''
        )}
      </div>
    )
  }
}

ModIdangerousSwiper.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.array.isRequired,
  onSlide: PropTypes.func,
  hasNavigation: PropTypes.bool,
  hasHomeNavigation: PropTypes.bool,
  hasPagination: PropTypes.bool,
  maxPaginationCount: PropTypes.number,
  hasFullScreen: PropTypes.bool,
  hasSlideNumber: PropTypes.bool,
  textSlideshowBlock: PropTypes.bool,
  paginationType: PropTypes.string,
  options: PropTypes.object,
}

ModIdangerousSwiper.getDefaultProps = {
  maxPagination: Number.MAX_SAFE_INTEGER,
}
