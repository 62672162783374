import React from 'react'
import ModTemplateBlockList from 'components/templates/ModTemplateBlockList'
import Mod404 from 'components/Mod404'
import ModEditBlock from 'components/common/ModEditBlock'
import PropTypes from 'prop-types'

export default class ModContent extends React.Component {
  render() {
    const {content, config, caasHelper, navigationTree, currentLanguage, pathname} = this.props

    let contentHtml = <Mod404 />
    let editMode = null

    if (content && content.article) {
      const title = content.article.i18n.hasOwnProperty(currentLanguage)
        ? content.article.i18n[currentLanguage].title
        : 'Site'
      if (config.editMode) {
        editMode = <ModEditBlock entityObject={content.article} title={title} config={config} />
      }

      if (content.article.content.hasOwnProperty('templateBlockListCorporate')) {
        contentHtml = (
          <ModTemplateBlockList
            templateData={content.article.content.templateBlockListCorporate}
            config={config}
            currentLanguage={currentLanguage}
            pathname={pathname}
            caasHelper={caasHelper}
            navigationNodeId={content.navigationNodeId}
            navigationTree={navigationTree}
          />
        )
      }
    }

    return (
      <div id="content">
        {editMode}
        {contentHtml}
      </div>
    )
  }
}

ModContent.propTypes = {
  config: PropTypes.object,
  content: PropTypes.object,
  caasHelper: PropTypes.object,
  navigationTree: PropTypes.object,
  currentLanguage: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
}
