import React from 'react'
import {FormattedMessage} from 'react-intl'
import PropTypes from 'prop-types'

export default class ModBlockInstagramLightWidget extends React.Component {
  componentDidMount() {
    try {
      Cookiebot.runScripts()
    } catch (error) {
      console.error(error)
    }
  }

  render() {
    const {content} = this.props

    return (
      <div className="block-instagram-light-widget">
        <div className="aspectRatio">
          <div className="cookieconsent-optout-marketing">
            <div className="cookieconsent-optout-marketing-custom">
              <h3>
                <a href="javascript:Cookiebot.renew()">
                  <FormattedMessage
                    id="cookiePolicy.acceptMarketing"
                    defaultMessage="Please accept marketing cookies to see this content."
                  />
                </a>
              </h3>
            </div>
          </div>
          <iframe
            className="lightwidget-widget"
            data-cookieblock-src={'/api/widgets/instagram.html?widgetId=' + content.widgetId}
            data-cookieconsent="marketing"
          />
        </div>
      </div>
    )
  }
}

ModBlockInstagramLightWidget.propTypes = {
  content: PropTypes.object,
}
