import React from 'react'
import ModVimeoPlayer from 'components/common/ModVimeoPlayer'
import ModBlockSnippet from 'components/blocks/ModBlockSnippet'
import ModBlockVimeo from 'components/blocks/ModBlockVimeo'
import {tags} from 'vo/VoConfig'
import {addCurrentLangRecursive} from 'common/DDUtil'
import {addClassToElement, removeClassFromElement} from '../common/DDUtil'
import PropTypes from 'prop-types'

export default class ModModalView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      overlayContent: null,
    }
    this.onClose = this.onClose.bind(this)
    this.setStateBy = this.setStateBy.bind(this)
    this.fetchOverlayContentById = this.fetchOverlayContentById.bind(this)
  }

  componentDidMount() {
    this.fetchOverlayContentById(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.fetchOverlayContentById(nextProps)
  }

  setStateBy(replaceObjects) {
    this.setState(Object.assign({}, this.state, replaceObjects))
  }

  fetchOverlayContentById(props) {
    const {caasHelper, navigationTree, location, currentLanguage} = props

    if (location.query && location.query.overlay) {
      caasHelper
        .fetchOverlay(navigationTree, location.query.overlay)
        .then((result) => {
          addCurrentLangRecursive(result, currentLanguage)
          this.setStateBy({
            overlayContent: result,
          })
        })
        .catch((error) => {
          this.setStateBy({
            overlayContent: null,
          })
        })
    } else {
      this.setStateBy({
        overlayContent: null,
      })
    }
  }

  onClose() {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  render() {
    const {location, currentLanguage, pathname} = this.props

    let className = 'hidden'
    let content = null
    if (this.state.overlayContent) {
      className = 'visible'
      const typeKey = Object.keys(this.state.overlayContent.content)[0]
      const blockData = this.state.overlayContent.content[typeKey]
      switch (typeKey) {
        case tags.blockTextSlideShow:
          console.log('ModModalView: blockTextSlideShow is not implemented for marche-int')
          break
        case tags.blockVimeo:
          content = <ModBlockVimeo content={blockData} size="big" />
          break
        case tags.blockSnippet:
          content = <ModBlockSnippet content={blockData} />
          break
      }
    } else if (location.query && location.query.overlayVimeoId) {
      className = 'visible'
      content = <ModVimeoPlayer vimeoId={location.query.overlayVimeoId} />
    }

    if (typeof window !== 'undefined') {
      let body = document.getElementsByTagName('BODY')[0]
      if (className === 'hidden') {
        removeClassFromElement(body, 'overflow-hidden')
      } else {
        addClassToElement(body, 'overflow-hidden')
      }
    }

    return (
      <div id="modal" className={className}>
        <div className="modal-content">
          {content}

          <div className="modal-close-button-wrapper">
            <div
              id="modal-close-button"
              className={className === 'visible' ? 'modal-button' : 'hidden'}
              onClick={this.onClose}
            >
              <img src={require('static/img/but_close_big_black.svg')} alt="close modal view" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ModModalView.propTypes = {
  onClose: PropTypes.func.isRequired,
  caasHelper: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  navigationTree: PropTypes.object.isRequired,
  currentLanguage: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
}
