import React from 'react'
import ModRichText from 'components/common/ModRichText'
import ModImgTag from 'components/common/ModImgTag'
import ModAnchorTag from 'components/common/ModAnchorTag'
import {FormattedDate, FormattedMessage} from 'react-intl'
import PropTypes from 'prop-types'

/**
 * block events can be shared over multiple websites. Therefore internal links are not possible
 */
export default class ModBlockEvents extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      eventsDetail: null,
    }

    this.onClick = this.onClick.bind(this)
  }

  onClick(e) {
    e.preventDefault()
    const {showEventsDetail, content} = this.props
    showEventsDetail({content})
  }

  render() {
    const {content, pathname, showEventsDetail} = this.props

    let i18n
    if (content._i18nCL && content._i18nCL.title) {
      i18n = content._i18nCL
    } else if (content.i18n.hasOwnProperty('en') && content.i18n.en.title) {
      i18n = content.i18n.en
    } else {
      return null
    }

    let link = null
    if (
      i18n.linkText &&
      content.link &&
      !(content.link.hasOwnProperty('url') && content.link.url === '')
    ) {
      link = (
        <div className="link-button">
          <ModAnchorTag linkObject={content.link} pathname={pathname} hasIcon={true}>
            {i18n.linkText}
          </ModAnchorTag>
        </div>
      )
    } else if (content.imageLeft && content.imageRight) {
      const sp = pathname.split('/')
      link = (
        <div className="link-button">
          <a className="a-internal" href="#" onClick={this.onClick}>
            <FormattedMessage id="modBlockEvents.moreInfo" defaultMessage="more info" />
          </a>
        </div>
      )
    }

    const eventDate =
      content.date !== 0 ? (
        <h6 className="block-events-date typo-h14">
          <FormattedDate
            value={new Date(content.date)}
            year="numeric"
            month="2-digit"
            day="2-digit"
          />
        </h6>
      ) : null

    return (
      <div className="block-events bg-white">
        <ModImgTag imgObject={content.imageSummary} width={400} height={270} />
        <div className="block-events-text-container">
          <div className="content">
            {eventDate}
            <h4 className="block-events-title typo-h6">{i18n.title}</h4>
            <ModRichText richText={i18n.summary} pathname={pathname} />
            {link}
          </div>
        </div>
      </div>
    )
  }
}

ModBlockEvents.propTypes = {
  content: PropTypes.shape({
    date: PropTypes.string.isRequired,
    imageSummary: PropTypes.object.isRequired,
    _i18nCL: PropTypes.object,
  }).isRequired,
  pathname: PropTypes.string.isRequired,
  showEventsDetail: PropTypes.func.isRequired,
}
