import React from 'react'
import ReactDOM from 'react-dom'
import {FormattedMessage} from 'react-intl'
import PropTypes from 'prop-types'

export default class ModVimeoPlayer extends React.Component {
  constructor(props) {
    super(props)

    this.vimeoPlayer = typeof Vimeo !== 'undefined'
    this.iFrame = null
    this.initVimeo = this.initVimeo.bind(this)
    this.onPlay = this.onPlay.bind(this)

    this.state = {
      isPlaying: !this.vimeoPlayer,
    }
  }

  componentDidMount() {
    try {
      Cookiebot.runScripts()
    } catch (error) {
      console.error(error)
    }

    this.initVimeo()
  }

  initVimeo() {
    // https://github.com/vimeo/player.js
    const iFrameNode = ReactDOM.findDOMNode(this.iFrame)
    if (iFrameNode && this.vimeoPlayer) {
      let player = new Vimeo.Player(iFrameNode)
      player.on('play', this.onPlay)
    }
  }

  onPlay() {
    setTimeout(() => {
      this.setState({
        isPlaying: true,
      })
    }, 500)
  }

  render() {
    const {vimeoId, isBackgroundVideo} = this.props
    const param = isBackgroundVideo ? '?background=1' : '' // autoplay=1&loop=1&
    return (
      <div className="vimeo-player">
        <div className="cookieconsent-optout-marketing">
          <div className="cookieconsent-optout-marketing-custom">
            <h3>
              <a href="javascript:Cookiebot.renew()">
                <FormattedMessage
                  id="cookiePolicy.acceptMarketing"
                  defaultMessage="Please accept marketing cookies to see this content."
                />
              </a>
            </h3>
          </div>
        </div>
        <iframe
          className={this.state.isPlaying ? 'is-playing' : ''}
          ref={(ref) => (this.iFrame = ref)}
          data-cookieblock-src={'https://player.vimeo.com/video/' + vimeoId + param}
          data-cookieconsent="marketing"
          frameBorder="0"
        ></iframe>
      </div>
    )
  }
}

ModVimeoPlayer.propTypes = {
  vimeoId: PropTypes.string,
  isBackgroundVideo: PropTypes.bool,
}
