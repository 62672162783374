import React from 'react'
import ModImgTag from 'components/common/ModImgTag'
import PropTypes from 'prop-types'

export default class ModBlockImage extends React.Component {
  render() {
    return (
      <div className="block-image bg-white">
        <ModImgTag imgObject={this.props.content.image} />
      </div>
    )
  }
}

ModBlockImage.propTypes = {
  content: PropTypes.object,
}
