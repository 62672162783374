import {hasContent} from 'common/DDUtil'

/**
 * siteName=Open graph site name
 * @param content
 * @param webSiteSettings
 * @param currentLanguage
 * @returns {{siteName: string, title: string, description: string, keywords: string, shareImage_url: string, shareImage_secureUrl: string, shareImage_format: string, shareImage_width: string, shareImage_height: string}}
 */
export function createMetaFields(content, webSiteSettings, currentLanguage) {
  const fields = {
    siteName: '',
    title: '',
    description: '',
    keywords: '',
    shareImage_url: '',
    shareImage_secureUrl: '',
    shareImage_format: '',
    shareImage_width: '',
    shareImage_height: '',
  }

  if (webSiteSettings) {
    const i18nSite =
      webSiteSettings.i18n && webSiteSettings.i18n.hasOwnProperty(currentLanguage)
        ? webSiteSettings.i18n[currentLanguage]
        : null
    if (i18nSite) {
      fields.siteName = i18nSite.siteName
      fields.title = i18nSite.title
      fields.description = i18nSite.description
      fields.keywords = i18nSite.keywords.join(',')
    }
    fields.shareImage_url = webSiteSettings.shareImage.cloudinaryMedia.url
    fields.shareImage_secureUrl = webSiteSettings.shareImage.cloudinaryMedia.secureUrl
    fields.shareImage_format = 'image/' + webSiteSettings.shareImage.cloudinaryMedia.format
    fields.shareImage_width = webSiteSettings.shareImage.cloudinaryMedia.width
    fields.shareImage_height = webSiteSettings.shareImage.cloudinaryMedia.height
  }

  if (content && content.article) {
    const {article} = content
    const i18n =
      article.i18n && article.i18n.hasOwnProperty(currentLanguage)
        ? article.i18n[currentLanguage]
        : null
    if (i18n) {
      if (hasContent(i18n.title)) {
        fields.title += ' | ' + i18n.title
      }
      if (hasContent(i18n.description)) {
        fields.description = i18n.description
      }
      if (hasContent(i18n.tags)) {
        fields.keywords = i18n.tags.toString()
      }
    }
    if (article.shareImage) {
      try {
        fields.shareImage_url = article.shareImage.cloudinaryMedia.url
        fields.shareImage_secureUrl = article.shareImage.cloudinaryMedia.secureUrl
        fields.shareImage_format = 'image/' + article.shareImage.cloudinaryMedia.format
        fields.shareImage_width = article.shareImage.cloudinaryMedia.width
        fields.shareImage_height = article.shareImage.cloudinaryMedia.height
      } catch (e) {}
    }
  }

  return fields
}
