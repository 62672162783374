import React from 'react'
import {FormattedMessage} from 'react-intl'
import PropTypes from 'prop-types'

export default class ModBlockSnippet extends React.Component {
  componentDidMount() {
    try {
      let snippet = this.props.content.snippet

      const parser = new DOMParser()
      const element = parser.parseFromString(snippet, 'text/html')
      const node = element.querySelector('iframe, script')

      if (node.nodeName == 'IFRAME') {
        node.setAttribute('data-cookieconsent', 'marketing')
        node.setAttribute('data-src', node.src)
        node.removeAttribute('src')
        this.element.appendChild(node)
      } else if (node.nodeName == 'SCRIPT') {
        node.setAttribute('type', 'text/plain')
        node.setAttribute('data-cookieconsent', 'marketing')
        this.element.appendChild(node)
      }

      Cookiebot.runScripts()
    } catch (error) {
      console.error(error)
    }
  }

  render() {
    const {content} = this.props

    let aspectRatio = content.aspectRatio.split(':')
    let style = {
      paddingBottom: (aspectRatio[1] / aspectRatio[0]) * 100 + '%',
    }

    return (
      <div className="block-snippet">
        <div className="aspectRatio" style={style}>
          <div className="cookieconsent-optout-marketing">
            <div className="cookieconsent-optout-marketing-custom" style={style}>
              <h3>
                <a href="javascript:Cookiebot.renew()">
                  <FormattedMessage
                    id="cookiePolicy.acceptMarketing"
                    defaultMessage="Please accept marketing cookies to see this content."
                  />
                </a>
              </h3>
            </div>
          </div>
          <div ref={(element) => (this.element = element)}></div>
        </div>
      </div>
    )
  }
}

ModBlockSnippet.propTypes = {
  content: PropTypes.object,
}
