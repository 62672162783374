import React from 'react'
import ModAnchorTag from 'components/common/ModAnchorTag'
import ModImgTag from 'components/common/ModImgTag'
import PropTypes from 'prop-types'

export default class ModBlockTeaserSpot extends React.Component {
  render() {
    const {content, pathname} = this.props

    const list = content.list.map(function (item, index) {
      return (
        <div key={index} className="block-teaser-spot-item">
          <ModAnchorTag linkObject={item.link} pathname={pathname}>
            <ModImgTag imgObject={item.image} width={266} height={266} />
          </ModAnchorTag>
        </div>
      )
    })

    return (
      <div className={'block-teaser-spot bg-white'}>
        <div className={'block-teaser-spot-wrapper items-' + content.list.length}>{list}</div>
      </div>
    )
  }
}

ModBlockTeaserSpot.propTypes = {
  content: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
}
