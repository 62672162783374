import React from 'react'
import ModRichText from 'components/common/ModRichText'
import ModAnchorTag from 'components/common/ModAnchorTag'
import ModImgTag from 'components/common/ModImgTag'
import {newsLetterFormLinks, newsLetterStrings} from 'common/Languages'
import PropTypes from 'prop-types'

export default class ModFooter extends React.Component {
  render() {
    const {content, pathname, currentLanguage} = this.props

    if (!content) {
      return null
    }

    const i18n = content.i18n.hasOwnProperty(currentLanguage) ? content.i18n[currentLanguage] : null
    if (!i18n) {
      return null
    }

    const newsLetterLink = newsLetterFormLinks[currentLanguage]
    const newsLetterString = newsLetterStrings[currentLanguage]

    const socialMedia = content.socialMedia.reduce((prevItem, item, index) => {
      if (item && item.hasOwnProperty('image') && item.image instanceof Object) {
        prevItem.push(
          <li key={index} className="socialMedia-links">
            <ModAnchorTag linkObject={item.link} pathname={pathname}>
              <ModImgTag imgObject={item.image} />
            </ModAnchorTag>
          </li>
        )
      }
      return prevItem
    }, [])

    const logos = content.logos.reduce((prevItem, item, index) => {
      let caption = null
      if (item.i18n.hasOwnProperty(currentLanguage)) {
        caption = <p>{item.i18n[currentLanguage].caption}</p>
      }
      prevItem.push(
        <li key={index}>
          <ModAnchorTag linkObject={item.link} pathname={pathname}>
            <ModImgTag imgObject={item.imageHover} width={122} className="footer-logo-colored" />
            <ModImgTag imgObject={item.image} width={122} className="footer-logo-grayscale" />
          </ModAnchorTag>
          {caption}
        </li>
      )
      return prevItem
    }, [])

    let newsletter = null
    if (!i18n.hideNewsletter) {
      newsletter = (
        <div className="footer-content-newsletter">
          <ModRichText richText={i18n.blockRight} pathname={pathname} />
          <a className="footer-newsletter" href={newsLetterLink} target="_blank" rel="noopener">
            {newsLetterString}
            <img src={require('static/img/arrow_right.png')} alt="" />
          </a>
        </div>
      )
    }

    return (
      <footer id="footer">
        <div className="footer-content-wrapper">
          <div className="footer-content">
            <div className="footer-content-left">
              <ModRichText richText={i18n.blockLeft} pathname={pathname} />
            </div>
            <div className="footer-content-center">
              <ModRichText richText={i18n.blockCenter} pathname={pathname} />
            </div>
            <div className="footer-content-right">
              {newsletter}

              <div className="footer-content-socialmedia">
                <h5 className="footer-content-socialmedia-title">{i18n.socialMediaTitle}</h5>
                <ul>{socialMedia}</ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-logo-wrapper">
          <div className="footer-logos">
            <p>{i18n.logosTitle}</p>
            <ul>{logos}</ul>
          </div>
        </div>
      </footer>
    )
  }
}

ModFooter.propTypes = {
  content: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
  currentLanguage: PropTypes.string.isRequired,
}
