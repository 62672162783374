import React from 'react'
import ModAnchorTag from 'components/common/ModAnchorTag'
import ModImgTag from 'components/common/ModImgTag'
import PropTypes from 'prop-types'

export default class ModBlockTestimonials extends React.Component {
  render() {
    const {content, pathname} = this.props

    if (content.quotes.length <= 0) {
      return null
    }

    const quote = content.quotes[0]

    if (!(quote._i18nCL.name && quote._i18nCL.quote)) {
      return null
    }

    const title = quote._i18nCL.name + ', ' + quote._i18nCL.jobTitle

    return (
      <div className="block-testimonials bg-white">
        <div className="block-testimonials-image-container">
          <ModAnchorTag linkObject={quote.link} pathname={pathname}>
            <ModImgTag imgObject={quote.image} width={350} height={350} />
          </ModAnchorTag>
        </div>
        <div className="block-testimonials-text-container">
          <h3 className="block-testimonials-quote typo-h10">{quote._i18nCL.quote}</h3>
          <p className="typo-h11">{title}</p>
        </div>
      </div>
    )
  }
}

ModBlockTestimonials.propTypes = {
  content: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
}
