import React from 'react'

export default class Mod404 extends React.Component {
  render() {
    return (
      <div className="layout-article">
        <div className="block-header">
          <div className="block-header-bg" />
          <h6 className="block-header-subtitle typo-h2">That’s an error</h6>
          <h1 className="block-header-title typo-h1-big">404</h1>
          <ul className="block-header-navigation" />
        </div>
        <div className="block-title bg-grey">
          <div className="block-title-bg" />
          <div className="block-title-triangle">
            <h2 className="typo-h3">Not found</h2>
            <p className="block-title-subtitle typo-h4">The requested URL was not found</p>
          </div>
        </div>
      </div>
    )
  }
}
