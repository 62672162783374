import React from 'react'
import PropTypes from 'prop-types'

export default class ModEditBlock extends React.Component {
  render() {
    const {entityObject, title, config} = this.props

    return (
      <div className="edit-mode">
        <div className="edit-mode-img">
          <img className="" src={require('static/img/karmaLogo.png')} />
        </div>

        <div className="edit-mode-text">
          <p className="typo-h6">{title}</p>
          <a
            className="typo-h7"
            href={
              config.publicConfig.caasEditorUrl +
              '/entries/' +
              entityObject._type +
              '/edit/' +
              entityObject._id
            }
            target="_blank"
            rel="noopener"
          >
            EDIT
          </a>
        </div>
      </div>
    )
  }
}

ModEditBlock.propTypes = {
  entityObject: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  config: PropTypes.object.isRequired,
}
