import React from 'react'
import ModImgTag from 'components/common/ModImgTag'
import ModSvg from 'components/common/ModSvg'
import {tags} from 'vo/VoConfig'
import {ArrowDown} from '../common/ModSvg'
import PropTypes from 'prop-types'

export default class ModBlockHeader extends React.Component {
  render() {
    const {content, voConfig} = this.props

    if (!content._i18nCL) {
      return null
    }
    const i18n = content._i18nCL

    let headerMenuPoints = <ul className="block-header-navigation" />
    if (this.props.blocks && this.props.pathname) {
      headerMenuPoints = (
        <ul className="block-header-navigation">
          {this.props.blocks.reduce((prevItem, item, index) => {
            if (
              item.typeKey === tags.blockTitle &&
              item.content.block._i18nCL &&
              item.content.block._i18nCL.anchor &&
              prevItem.length < 6
            ) {
              prevItem.push(
                <li key={index} className="typo-h5 icon">
                  <ModSvg icon={ArrowDown} />
                  <a
                    href={
                      this.props.pathname + '#' + item.content.block._i18nCL.anchor.toLowerCase()
                    }
                  >
                    {item.content.block._i18nCL.title}
                  </a>
                </li>
              )
            }
            return prevItem
          }, [])}
        </ul>
      )
    }

    return (
      <div className="block-header">
        <div className="block-header-bg" />
        <div className="img-wrapper">
          <ModImgTag imgObject={content.image} width={348} height={392} />
        </div>
        <h6 className="block-header-subtitle typo-h2">{i18n.subtitle}</h6>
        <h1 className={'block-header-title typo-h1-big'}>{i18n.title}</h1>
        {headerMenuPoints}
      </div>
    )
  }
}

ModBlockHeader.propTypes = {
  content: PropTypes.shape({
    _i18nCL: PropTypes.object.isRequired,
  }).isRequired,
  blocks: PropTypes.array,
  voConfig: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
}
